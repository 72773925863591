// Variables

$enable-negative-margins: true !default;

// Grays
$white: #fff !default;
$gray-50: #fbfbfb !default;
$gray-100: #f5f5f5 !default;
$gray-200: #eeeeee !default;
$gray-300: #e0e0e0 !default;
$gray-400: #bdbdbd !default;
$gray-500: #9e9e9e !default;
$gray-600: #757575 !default;
$gray-700: #616161 !default;
$gray-800: #4f4f4f !default;
$gray-900: #262626 !default;
$black: #000 !default;

// Material Design Color System - start
$blue-50: #e3f2fd !default;
$blue-100: #bbdefb !default;
$blue-200: #90caf9 !default;
$blue-300: #64b5f6 !default;
$blue-400: #42a5f5 !default;
$blue-500: #2196f3 !default;
$blue-600: #1e88e5 !default;
$blue-700: #1976d2 !default;
$blue-800: #1565c0 !default;
$blue-900: #0d47a1 !default;
$blue-accent-100: #82b1ff !default;
$blue-accent-200: #448aff !default;
$blue-accent-400: #2979ff !default;
$blue-accent-700: #2962ff !default;

$indigo-50: #e8eaf6 !default;
$indigo-100: #c5cae9 !default;
$indigo-200: #9fa8da !default;
$indigo-300: #7986cb !default;
$indigo-400: #5c6bc0 !default;
$indigo-500: #3f51b5 !default;
$indigo-600: #3949ab !default;
$indigo-700: #303f9f !default;
$indigo-800: #283593 !default;
$indigo-900: #1a237e !default;
$indigo-accent-100: #8c9eff !default;
$indigo-accent-200: #536dfe !default;
$indigo-accent-400: #3d5afe !default;
$indigo-accent-700: #304ffe !default;

$purple-50: #f3e5f5 !default;
$purple-100: #e1bee7 !default;
$purple-200: #ce93d8 !default;
$purple-300: #ba68c8 !default;
$purple-400: #ab47bc !default;
$purple-500: #9c27b0 !default;
$purple-600: #8e24aa !default;
$purple-700: #7b1fa2 !default;
$purple-800: #6a1b9a !default;
$purple-900: #4a148c !default;
$purple-accent-100: #ea80fc !default;
$purple-accent-200: #e040fb !default;
$purple-accent-400: #d500f9 !default;
$purple-accent-700: #aa00ff !default;

$pink-50: #fce4ec !default;
$pink-100: #f8bbd0 !default;
$pink-200: #f48fb1 !default;
$pink-300: #f06292 !default;
$pink-400: #ec407a !default;
$pink-500: #e91e63 !default;
$pink-600: #d81b60 !default;
$pink-700: #c2185b !default;
$pink-800: #ad1457 !default;
$pink-900: #880e4f !default;
$pink-accent-100: #ff80ab !default;
$pink-accent-200: #ff4081 !default;
$pink-accent-400: #f50057 !default;
$pink-accent-700: #c51162 !default;

$red-50: #ffebee !default;
$red-100: #ffcdd2 !default;
$red-200: #ef9a9a !default;
$red-300: #e57373 !default;
$red-400: #ef5350 !default;
$red-500: #f44336 !default;
$red-600: #e53935 !default;
$red-700: #d32f2f !default;
$red-800: #c62828 !default;
$red-900: #b71c1c !default;
$red-accent-100: #ff8a80 !default;
$red-accent-200: #ff5252 !default;
$red-accent-400: #ff1744 !default;
$red-accent-700: #d50000 !default;

$orange-50: #fff3e0 !default;
$orange-100: #ffe0b2 !default;
$orange-200: #ffcc80 !default;
$orange-300: #ffb74d !default;
$orange-400: #ffa726 !default;
$orange-500: #ff9800 !default;
$orange-600: #fb8c00 !default;
$orange-700: #f57c00 !default;
$orange-800: #ef6c00 !default;
$orange-900: #e65100 !default;
$orange-accent-100: #ffd180 !default;
$orange-accent-200: #ffab40 !default;
$orange-accent-400: #ff9100 !default;
$orange-accent-700: #ff6d00 !default;

$yellow-50: #fffde7 !default;
$yellow-100: #fff9c4 !default;
$yellow-200: #fff59d !default;
$yellow-300: #fff176 !default;
$yellow-400: #ffee58 !default;
$yellow-500: #ffeb3b !default;
$yellow-600: #fdd835 !default;
$yellow-700: #fbc02d !default;
$yellow-800: #f9a825 !default;
$yellow-900: #f57f17 !default;
$yellow-accent-100: #ffff8d !default;
$yellow-accent-200: #ffff00 !default;
$yellow-accent-400: #ffea00 !default;
$yellow-accent-700: #ffd600 !default;

$green-50: #e8f5e9 !default;
$green-100: #c8e6c9 !default;
$green-200: #a5d6a7 !default;
$green-300: #81c784 !default;
$green-400: #66bb6a !default;
$green-500: #4caf50 !default;
$green-600: #43a047 !default;
$green-700: #388e3c !default;
$green-800: #2e7d32 !default;
$green-900: #1b5e20 !default;
$green-accent-100: #b9f6ca !default;
$green-accent-200: #69f0ae !default;
$green-accent-400: #00e676 !default;
$green-accent-700: #00c853 !default;

$teal-50: #e0f2f1 !default;
$teal-100: #b2dfdb !default;
$teal-200: #80cbc4 !default;
$teal-300: #4db6ac !default;
$teal-400: #26a69a !default;
$teal-500: #009688 !default;
$teal-600: #00897b !default;
$teal-700: #00796b !default;
$teal-800: #00695c !default;
$teal-900: #004d40 !default;
$teal-accent-100: #a7ffeb !default;
$teal-accent-200: #64ffda !default;
$teal-accent-400: #1de9b6 !default;
$teal-accent-700: #00bfa5 !default;

$cyan-50: #e0f7fa !default;
$cyan-100: #b2ebf2 !default;
$cyan-200: #80deea !default;
$cyan-300: #4dd0e1 !default;
$cyan-400: #26c6da !default;
$cyan-500: #00bcd4 !default;
$cyan-600: #00acc1 !default;
$cyan-700: #0097a7 !default;
$cyan-800: #00838f !default;
$cyan-900: #006064 !default;
$cyan-accent-100: #84ffff !default;
$cyan-accent-200: #18ffff !default;
$cyan-accent-400: #00e5ff !default;
$cyan-accent-700: #00b8d4 !default;

$deep-purple-50: #ede7f6 !default;
$deep-purple-100: #d1c4e9 !default;
$deep-purple-200: #b39ddb !default;
$deep-purple-300: #9575cd !default;
$deep-purple-400: #7e57c2 !default;
$deep-purple-500: #673ab7 !default;
$deep-purple-600: #5e35b1 !default;
$deep-purple-700: #512da8 !default;
$deep-purple-800: #4527a0 !default;
$deep-purple-900: #311b92 !default;
$deep-purple-accent-100: #b388ff !default;
$deep-purple-accent-200: #7c4dff !default;
$deep-purple-accent-400: #651fff !default;
$deep-purple-accent-700: #6200ea !default;

$light-blue-50: #e1f5fe !default;
$light-blue-100: #b3e5fc !default;
$light-blue-200: #81d4fa !default;
$light-blue-300: #4fc3f7 !default;
$light-blue-400: #29b6f6 !default;
$light-blue-500: #03a9f4 !default;
$light-blue-600: #039be5 !default;
$light-blue-700: #0288d1 !default;
$light-blue-800: #0277bd !default;
$light-blue-900: #01579b !default;
$light-blue-accent-100: #80d8ff !default;
$light-blue-accent-200: #40c4ff !default;
$light-blue-accent-400: #00b0ff !default;
$light-blue-accent-700: #0091ea !default;

$light-green-50: #f1f8e9 !default;
$light-green-100: #dcedc8 !default;
$light-green-200: #c5e1a5 !default;
$light-green-300: #aed581 !default;
$light-green-400: #9ccc65 !default;
$light-green-500: #8bc34a !default;
$light-green-600: #7cb342 !default;
$light-green-700: #689f38 !default;
$light-green-800: #558b2f !default;
$light-green-900: #33691e !default;
$light-green-accent-100: #ccff90 !default;
$light-green-accent-200: #b2ff59 !default;
$light-green-accent-400: #76ff03 !default;
$light-green-accent-700: #64dd17 !default;

$lime-50: #f9fbe7 !default;
$lime-100: #f0f4c3 !default;
$lime-200: #e6ee9c !default;
$lime-300: #dce775 !default;
$lime-400: #d4e157 !default;
$lime-500: #cddc39 !default;
$lime-600: #c0ca33 !default;
$lime-700: #afb42b !default;
$lime-800: #9e9d24 !default;
$lime-900: #827717 !default;
$lime-accent-100: #f4ff81 !default;
$lime-accent-200: #eeff41 !default;
$lime-accent-400: #c6ff00 !default;
$lime-accent-700: #aeea00 !default;

$amber-50: #fff8e1 !default;
$amber-100: #ffecb3 !default;
$amber-200: #ffe082 !default;
$amber-300: #ffd54f !default;
$amber-400: #ffca28 !default;
$amber-500: #ffc107 !default;
$amber-600: #ffb300 !default;
$amber-700: #ffa000 !default;
$amber-800: #ff8f00 !default;
$amber-900: #ff6f00 !default;
$amber-accent-100: #ffe57f !default;
$amber-accent-200: #ffd740 !default;
$amber-accent-400: #ffc400 !default;
$amber-accent-700: #ffab00 !default;

$deep-orange-50: #fbe9e7 !default;
$deep-orange-100: #ffccbc !default;
$deep-orange-200: #ffab91 !default;
$deep-orange-300: #ff8a65 !default;
$deep-orange-400: #ff7043 !default;
$deep-orange-500: #ff5722 !default;
$deep-orange-600: #f4511e !default;
$deep-orange-700: #e64a19 !default;
$deep-orange-800: #d84315 !default;
$deep-orange-900: #bf360c !default;
$deep-orange-accent-100: #ff9e80 !default;
$deep-orange-accent-200: #ff6e40 !default;
$deep-orange-accent-400: #ff3d00 !default;
$deep-orange-accent-700: #dd2c00 !default;

$brown-50: #efebe9 !default;
$brown-100: #d7ccc8 !default;
$brown-200: #bcaaa4 !default;
$brown-300: #a1887f !default;
$brown-400: #8d6e63 !default;
$brown-500: #795548 !default;
$brown-600: #6d4c41 !default;
$brown-700: #5d4037 !default;
$brown-800: #4e342e !default;
$brown-900: #3e2723 !default;

$blue-gray-50: #eceff1 !default;
$blue-gray-100: #cfd8dc !default;
$blue-gray-200: #b0bec5 !default;
$blue-gray-300: #90a4ae !default;
$blue-gray-400: #78909c !default;
$blue-gray-500: #607d8b !default;
$blue-gray-600: #546e7a !default;
$blue-gray-700: #455a64 !default;
$blue-gray-800: #37474f !default;
$blue-gray-900: #263238 !default;
// Material Design Color System - end

// Theme colors
$primary: #1266f1 !default;
$secondary: #b23cfd !default;
$success: #00b74a !default;
$info: #39c0ed !default;
$warning: #ffa900 !default;
$danger: #f93154 !default;
$neon: #26ddff !default;
$light: $gray-50 !default;
$dark: $gray-900 !default;
$theme1: #c42175 !default;
$theme2: #00a6ff !default;

// scss-docs-start theme-colors-map
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
  "white": $white,
  "black": $black,
) !default;
// scss-docs-end theme-colors-map

// scss-docs-start note-colors-map
$note: () !default;
$note: map-merge(
  (
    "primary": (
      "bgc": lighten($primary, 43%),
      "border-color": $primary,
    ),
    "secondary": (
      "bgc": lighten($secondary, 33%),
      "border-color": $secondary,
    ),
    "success": (
      "bgc": lighten($success, 53%),
      "border-color": $success,
    ),
    "danger": (
      "bgc": lighten($danger, 36%),
      "border-color": $danger,
    ),
    "warning": (
      "bgc": lighten($warning, 42%),
      "border-color": $warning,
    ),
    "info": (
      "bgc": lighten($info, 36%),
      "border-color": $info,
    ),
    "light": (
      "bgc": $light,
      "border-color": $dark,
    ),
  ),
  $note
);
// scss-docs-end note-colors-map

// Body
//
// Settings for the `<body>` element.

$body-color: $gray-800 !default;

// Components
//
// Shadows offset, blur, spread and color

$box-shadow-0: none !default;
$box-shadow-1: 0 2px 10px 0 rgba(0, 0, 0, 0.05) !default;
$box-shadow-2: 0 5px 15px 0 rgba(0, 0, 0, 0.05) !default;
$box-shadow-3: 0 10px 20px 0 rgba(0, 0, 0, 0.05) !default;
$box-shadow-4: 0 15px 30px 0 rgba(0, 0, 0, 0.05) !default;
$box-shadow-5: 0 20px 40px 0 rgba(0, 0, 0, 0.05) !default;

$box-shadow-1-strong: 0px 2px 5px 0px rgba(0, 0, 0, 0.25), 0px 3px 10px 0px rgba(0, 0, 0, 0.2) !default;
$box-shadow-2-strong: 0px 4px 8px 0px rgba(0, 0, 0, 0.25), 0px 5px 15px 2px rgba(0, 0, 0, 0.2) !default;
$box-shadow-3-strong: 0px 6px 11px 0px rgba(0, 0, 0, 0.25), 0px 7px 20px 3px rgba(0, 0, 0, 0.2) !default;
$box-shadow-4-strong: 0px 6px 14px 0px rgba(0, 0, 0, 0.25), 0px 10px 30px 4px rgba(0, 0, 0, 0.2) !default;
$box-shadow-5-strong: 0px 6px 20px 0px rgba(0, 0, 0, 0.25), 0px 12px 40px 5px rgba(0, 0, 0, 0.2) !default;

$box-shadow-3-top: 0 -10px 20px 0 rgba(0, 0, 0, 0.05) !default;

// scss-docs-start light-shadows-values
$shadows-light: (
  "0": $box-shadow-0,
  "1": $box-shadow-1,
  "2": $box-shadow-2,
  "3": $box-shadow-3,
  "4": $box-shadow-4,
  "5": $box-shadow-5,
) !default;
// scss-docs-end light-shadows-values

// scss-docs-start strong-shadows-values
$shadows-strong: (
  "1": $box-shadow-1-strong,
  "2": $box-shadow-2-strong,
  "3": $box-shadow-3-strong,
  "4": $box-shadow-4-strong,
  "5": $box-shadow-5-strong,
) !default;
// scss-docs-end strong-shadows-values

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-roboto: "Roboto", sans-serif !default;
$font-family-base: var(--mdb-font-roboto) !default;

$line-height-base: 1.6 !default;

// Buttons
//
// For each of MDB's buttons, define text, background, and border color.

$btn-line-height: 1.5 !default;
$btn-padding-top: 0.625rem !default;
$btn-padding-bottom: 0.5rem !default;
$btn-font-size: 0.75rem !default;
$btn-padding-left: 1.5rem !default;
$btn-padding-right: 1.5rem !default;
$btn-font-weight: 500 !default;
$btn-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.1) !default;
$btn-hover-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 4px 20px 0 rgba(0, 0, 0, 0.1) !default;
$btn-focus-box-shadow: $btn-hover-box-shadow !default;
$btn-active-box-shadow: $btn-hover-box-shadow !default;
$btn-border-width: 0.125rem !default;

$btn-outline-padding-top: 0.5rem !default;
$btn-outline-padding-bottom: 0.375rem !default;
$btn-outline-padding-left: 1.375rem !default;
$btn-outline-padding-right: 1.375rem !default;

$btn-outline-padding-top-sm: 0.25rem !default;
$btn-outline-padding-bottom-sm: 0.1875rem !default;
$btn-outline-padding-right-sm: 0.875rem !default;
$btn-outline-padding-left-sm: 0.875rem !default;

$btn-outline-padding-top-lg: 0.625rem !default;
$btn-outline-padding-bottom-lg: 0.5625rem !default;
$btn-outline-padding-right-lg: 1.5625rem !default;
$btn-outline-padding-left-lg: 1.5625rem !default;

$btn-line-height-lg: 1.6 !default;
$btn-font-size-lg: 0.875rem !default;
$btn-padding-top-lg: 0.75rem !default;
$btn-padding-bottom-lg: 0.6875rem !default;
$btn-padding-right-lg: 1.6875rem !default;
$btn-padding-left-lg: 1.6875rem !default;

$btn-line-height-sm: $btn-line-height !default;
$btn-font-size-sm: 0.75rem !default;
$btn-padding-top-sm: 0.375rem !default;
$btn-padding-bottom-sm: 0.3125rem !default;
$btn-padding-right-sm: 1rem !default;
$btn-padding-left-sm: 1rem !default;

$btn-link-bgc: #f5f5f5 !default;
$btn-rounded-border-radius: 10rem !default;

$btn-floating-border-radius: 50% !default;

$btn-floating-width: 2.3125rem !default;
$btn-floating-height: 2.3125rem !default;
$btn-floating-icon-width: 2.3125rem !default;
$btn-floating-icon-line-height: 2.3125rem !default;

$btn-floating-width-lg: 2.8125rem !default;
$btn-floating-height-lg: 2.8125rem !default;
$btn-floating-icon-width-lg: 2.8125rem !default;
$btn-floating-icon-line-height-lg: 2.8125rem !default;

$btn-floating-width-sm: 1.8125rem !default;
$btn-floating-height-sm: 1.8125rem !default;
$btn-floating-icon-width-sm: 1.8125rem !default;
$btn-floating-icon-line-height-sm: 1.8125rem !default;

$btn-outline-floating-icon-width: 2.0625rem !default;
$btn-outline-floating-icon-line-height: 2.0625rem !default;

$btn-outline-floating-icon-width-lg: 2.5625rem !default;
$btn-outline-floating-icon-line-height-lg: 2.5625rem !default;

$btn-outline-floating-icon-width-sm: 1.5625rem !default;
$btn-outline-floating-icon-line-height-sm: 1.5625rem !default;

$btn-floating-border-radius: 50% !default;

$btn-block-spacing-y: 0.5rem !default;

$fixed-action-btn-right: 2.1875rem !default;
$fixed-action-btn-bottom: 2.1875rem !default;
$fixed-action-btn-padding-top: 0.9375rem !default;
$fixed-action-btn-li-margin-bottom: 1.5rem !default;

// Button group
//
// Material styling for group of buttons

$btn-group-border-radius: calc(4px - 1px) !default;
$btn-group-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out !default;

// Forms
//
// Material styling for outline forms border, label, size

$input-padding-top: 0.33em !default;
$input-padding-bottom: $input-padding-top !default;
$input-padding-left: 0.75em !default;
$input-padding-right: $input-padding-left !default;
$input-transition: all 0.2s linear !default;

$form-label-left: 0.75rem !default;
$form-label-padding-top: 0.37rem !default;
$form-label-transition: all 0.2s ease-out !default;
$form-label-color: rgba(0, 0, 0, 0.6) !default;

$input-focus-active-label-transform: translateY(-1rem) translateY(0.1rem) scale(0.8) !default;
$input-focus-label-color: $primary !default;
$input-focus-border-width: 0.125rem !default;
$input-focus-border-color: $primary !default;
$input-disabled-background-color: #e9ecef !default;

$input-padding-left-lg: 0.75em !default;
$input-padding-right-lg: $input-padding-left-lg !default;
$input-font-size-lg: 1rem !default;
$input-line-height-lg: 2.15 !default;
$input-focus-active-label-transform-lg: translateY(-1.25rem) translateY(0.1rem) scale(0.8) !default;

$input-padding-left-sm: 0.99em !default;
$input-padding-right-sm: $input-padding-left-sm !default;
$input-padding-top-sm: 0.43em !default;
$input-padding-bottom-sm: 0.35em !default;
$input-font-size-sm: 0.775rem !default;
$input-line-height-sm: 1.6 !default;
$input-focus-active-label-transform-sm: translateY(-0.85rem) translateY(0.1rem) scale(0.8) !default;

$form-notch-div-border-color: #bdbdbd !default;
$form-notch-leading-width: 0.5rem !default;
$form-notch-leading-border-radius: 0.25rem !default;
$form-notch-middle-max-width: 1rem !default;
$form-notch-trailing-border-radius: $form-notch-leading-border-radius !default;

$form-label-padding-top-lg: 0.7rem !default;
$form-label-padding-top-sm: 0.33rem !default;
$form-label-font-size-sm: 0.775rem !default;

$form-white-input-color: $white !default;
$form-white-label-color: $gray-50 !default;
$form-white-notch-div-border-color: $gray-50 !default;
$form-white-input-focus-label-color: $white !default;
$form-white-input-focus-border-color: $white !default;

$form-check-input-margin-left: 1.79rem !default;
$form-check-input-radio-margin-left: 1.85rem !default;
$form-check-input-width-md: 1.125rem !default;
$form-check-input-height: $form-check-input-width-md !default;
$form-check-input-background-color: $white !default;
$form-check-input-border-width: 0.125rem !default;
$form-check-input-border-color: rgb(117, 117, 117) !default;

$form-check-input-before-box-shadow: 0px 0px 0px 13px transparent !default;
$form-check-input-before-border-radius: 50% !default;
$form-check-input-before-width: 0.875rem !default;
$form-check-input-before-height: $form-check-input-before-width !default;
$form-check-input-before-transform: scale(0) !default;

$form-check-input-hover-before-opacity: 0.04 !default;
$form-check-input-hover-before-box-shadow: 0px 0px 0px 13px rgba(0, 0, 0, 0.6) !default;

$form-check-input-focus-border-color: $form-check-input-border-color !default;
$form-check-input-focus-transition: border-color 0.2s !default;

$form-check-input-focus-before-opacity: 0.12 !default;
$form-check-input-focus-before-box-shadow: $form-check-input-hover-before-box-shadow !default;
$form-check-input-focus-before-transform: scale(1) !default;
$form-check-input-focus-before-transition: box-shadow 0.2s, transform 0.2s !default;

$form-check-input-checked-border-color: $primary !default;
$form-check-input-checked-before-opacity: 0.16 !default;
$form-check-input-checked-focus-border-color: $primary !default;
$form-check-input-checked-focus-before-box-shadow: 0px 0px 0px 13px $primary !default;
$form-check-input-checked-focus-before-transform: $form-check-input-focus-before-transform !default;
$form-check-input-checked-focus-before-transition: $form-check-input-focus-before-transition !default;

$form-check-input-checkbox-border-radius: 0.125rem !default;
$form-check-input-checkbox-focus-after-width: 0.875rem !default;
$form-check-input-checkbox-focus-after-height: $form-check-input-checkbox-focus-after-width !default;
$form-check-input-checkbox-focus-after-background-color: $white !default;
$form-check-input-checkbox-checked-background-color: $primary !default;
$form-check-input-checkbox-checked-focus-background-color: $primary !default;

$form-check-input-checkbox-checked-after-transform: rotate(45deg) !default;
$form-check-input-checkbox-checked-after-border-width: 0.125rem !default;
$form-check-input-checkbox-checked-after-border-color: $white !default;
$form-check-input-checkbox-checked-after-width: 0.375rem !default;
$form-check-input-checkbox-checked-after-height: 0.8125rem !default;
$form-check-input-checkbox-checked-after-margin-left: 0.25rem !default;
$form-check-input-checkbox-checked-after-margin-top: -1px !default;

$form-check-input-indeterminate-focus-before-box-shadow: 0px 0px 0px 13px $primary !default;
$form-check-input-indeterminate-checked-background-color: $primary !default;
$form-check-input-indeterminate-checked-after-transform: rotate(90deg) !default;
$form-check-input-indeterminate-checked-after-border-width: 0.125rem !default;
$form-check-input-indeterminate-checked-after-border-color: $white !default;
$form-check-input-indeterminate-checked-after-width: 0.125rem !default;
$form-check-input-indeterminate-checked-after-height: 0.875rem !default;
$form-check-input-indeterminate-checked-after-margin-left: 0.375rem !default;
$form-check-input-indeterminate-focus-background-color: $primary !default;
$form-check-input-indeterminate-focus-border-color: $primary !default;
$form-check-input-indeterminate-border-color: $gray-600 !default;

$form-check-input-radio-border-radius: 50% !default;
$form-check-input-radio-width: 1.25rem !default;
$form-check-input-radio-height: $form-check-input-radio-width !default;

$form-check-input-radio-before-width: 1rem !default;
$form-check-input-radio-before-height: $form-check-input-radio-before-width !default;

$form-check-input-radio-after-width: $form-check-input-radio-before-width !default;
$form-check-input-radio-after-height: $form-check-input-radio-after-width !default;
$form-check-input-radio-after-border-radius: $form-check-input-radio-border-radius !default;
$form-check-input-radio-after-background-color: $white !default;

$form-check-input-radio-checked-background-color: $white !default;
$form-check-input-radio-checked-after-border-radius: $form-check-input-radio-border-radius !default;
$form-check-input-radio-checked-after-width: 0.625rem !default;
$form-check-input-radio-checked-after-height: $form-check-input-radio-checked-after-width !default;
$form-check-input-radio-checked-after-border-color: $primary !default;
$form-check-input-radio-checked-after-background-color: $primary !default;
$form-check-input-radio-checked-after-margin-top: 0.1875rem !default;
$form-check-input-radio-checked-after-margin-left: 0.1875rem !default;
$form-check-input-radio-checked-after-transition: border-color !default;
$form-check-input-radio-checked-focus-background-color: $white !default;

$form-switch-padding-left: 1.85rem !default;
$form-switch-form-check-input-border-radius: 0.4375rem !default;
$form-switch-form-check-input-width: 2.25rem !default;
$form-switch-form-check-input-height: 0.875rem !default;
$form-switch-form-check-input-background-color: rgba(0, 0, 0, 0.38) !default;
$form-switch-form-check-input-margin-top: 0.35rem !default;
$form-switch-form-check-input-margin-right: 0.7rem !default;

$form-switch-form-check-input-after-border-radius: 50% !default;
$form-switch-form-check-input-after-width: 1.25rem !default;
$form-switch-form-check-input-after-height: $form-switch-form-check-input-after-width !default;
$form-switch-form-check-input-after-background-color: $white !default;
$form-switch-form-check-input-after-margin-top: -0.1875rem !default;
$form-switch-form-check-input-after-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
  0 1px 5px 0 rgba(0, 0, 0, 0.12) !default;
$form-switch-form-check-input-after-transition: background-color 0.2s, transform 0.2s !default;

$form-switch-form-check-input-focus-before-box-shadow: 3px -1px 0px 13px rgba(0, 0, 0, 0.6) !default;
$form-switch-form-check-input-focus-before-transform: scale(1) !default;
$form-switch-form-check-input-focus-before-transition: box-shadow 0.2s, transform 0.2s !default;

$form-switch-form-check-input-focus-after-border-radius: $form-switch-form-check-input-after-border-radius !default;
$form-switch-form-check-input-focus-after-width: 1.25rem !default;
$form-switch-form-check-input-focus-after-height: $form-switch-form-check-input-focus-after-width !default;

$form-switch-form-check-input-checked-focus-before-margin-left: 1.0625rem !default;
$form-switch-form-check-input-checked-focus-before-box-shadow: 3px -1px 0px 13px $primary !default;
$form-switch-form-check-input-checked-focus-before-transform: scale(1) !default;
$form-switch-form-check-input-checked-focus-before-transition: box-shadow 0.2s, transform 0.2s !default;

$form-switch-form-check-input-checked-checkbox-after-border-radius: 50% !default;
$form-switch-form-check-input-checked-checkbox-after-width: 1.25rem !default;
$form-switch-form-check-input-checked-checkbox-after-height: 1.25rem !default;
$form-switch-form-check-input-checked-checkbox-after-background-color: $primary !default;
$form-switch-form-check-input-checked-checkbox-after-margin-top: -3px !default;
$form-switch-form-check-input-checked-checkbox-after-margin-left: 1.0625rem !default;
$form-switch-form-check-input-checked-checkbox-after-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
  0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12) !default;
$form-switch-form-check-input-checked-checkbox-after-transition: background-color 0.2s, transform 0.2s !default;

$form-file-height: calc(1.79rem + 0.33rem + 2px) !default;

$form-file-label-focus-border-color: $primary !default;
$form-file-label-focus-transition: all 0.2s linear !default;
$form-file-label-focus-text-border-width: 2px 1px 2px 2px !default;
$form-file-label-focus-button-border-width: 2px 2px 2px 1px !default;
$form-file-label-disabled-background-color: #e9ecef !default;
$form-file-label-height: $form-file-height !default;
$form-file-label-border-color: #bdbdbd !default;
$form-file-label-border-radius: 0.2rem !default;

$form-file-text-padding-y: 0.33rem !default;
$form-file-text-border-color: #bdbdbd !default;

$form-file-button-line-height: 1.5 !default;
$form-file-button-background-color: #fff !default;
$form-file-button-border-color: $form-file-text-border-color !default;

$form-file-sm-height: calc(1.33rem + 0.33rem + 2px) !default;
$form-file-sm-font-size: 0.775rem !default;
$form-file-sm-line-height: 1.5 !default;
$form-file-sm-padding-y: $form-file-text-padding-y !default;

$form-file-lg-height: calc(2.315rem + 0.33rem + 2px) !default;
$form-file-lg-font-size: 1rem !default;
$form-file-lg-line-height: 2.15 !default;
$form-file-lg-padding-y: $form-file-text-padding-y !default;

$form-range-webkit-slider-thumb-margin-top: -6px !default;
$form-range-webkit-slider-runnable-track-height: 4px !default;

$input-group-height: calc(1.75rem + 0.33rem + 2px) !default;
$input-group-min-height: $input-group-height !default;
$input-group-padding-y: 0.27rem !default;
$input-group-transition: all 0.2s linear !default;

$input-group-focus-transition: $input-group-transition !default;
$input-group-focus-border-color: $primary !default;
$input-group-focus-box-shadow: inset 0 0 0 1px $primary !default;

$input-group-text-padding-y: 0.26rem !default;

$input-group-lg-height: calc(2.315rem + 0.33rem + 2px) !default;
$input-group-lg-font-size: 1rem !default;
$input-group-lg-padding-y: 0.33rem !default;
$input-group-lg-text-font-size: $input-group-lg-font-size !default;

$input-group-sm-height: calc(1.33rem + 0.33rem + 2px) !default;
$input-group-sm-min-height: $input-group-sm-height !default;
$input-group-sm-font-size: 0.775rem !default;
$input-group-sm-padding-y: $input-group-lg-padding-y !default;
$input-group-sm-text-font-size: $input-group-sm-font-size !default;
$input-group-sm-text-line-height: 1.6 !default;

$input-group-form-outline-border-left-width: 1px !default;
$input-group-form-outline-border-left-color: #bdbdbd !default;

// Form validation

$form-feedback-valid-tooltip-color: $white !default;
$form-feedback-valid-color: $success !default;
$form-feedback-invalid-color: $danger !default;

// scss-docs-start form-validation-states
$form-validation-states-mdb: (
  "valid": (
    "color": $form-feedback-valid-color,
  ),
  "invalid": (
    "color": $form-feedback-invalid-color,
  ),
) !default;
// scss-docs-end form-validation-states

// scss-docs-start zindex-stack
$zindex-fixed-action-button: 1030 !default;
$zindex-toast: 1060 !default;
$zindex-alert: 1070 !default;
$zindex-popover: 1080 !default;
$zindex-tooltip: 1090 !default;
// scss-docs-end zindex-stack

// Navs

$nav-tabs-link-border-width: 0 0 2px 0 !default;
$nav-tabs-link-font-weight: 500 !default;
$nav-tabs-link-font-size: 12px !default;
$nav-tabs-link-color: rgba(0, 0, 0, 0.6) !default;
$nav-tabs-link-padding-top: 17px !default;
$nav-tabs-link-padding-bottom: 16px !default;
$nav-tabs-link-padding-x: 29px !default;
$nav-tabs-link-hover-background-color: #f5f5f5 !default;

$nav-pills-margin: 0.5rem !default;
$nav-pills-link-border-radius: 0.25rem !default;
$nav-pills-link-font-size: $nav-tabs-link-font-size !default;
$nav-pills-link-padding-top: $nav-tabs-link-padding-top !default;
$nav-pills-link-padding-bottom: $nav-tabs-link-padding-bottom !default;
$nav-pills-link-padding-x: $nav-tabs-link-padding-x !default;
$nav-pills-link-line-height: 1 !default;
$nav-pills-link-background-color: $nav-tabs-link-hover-background-color !default;
$nav-pills-link-font-weight: $nav-tabs-link-font-weight !default;
$nav-pills-link-color: $nav-tabs-link-color !default;

// Navbar

$navbar-box-shadow: $box-shadow-3 !default;
$navbar-padding-top: 0.5625rem !default;
$navbar-brand-img-margin-right: 0.25rem !default;

// Dropdowns
//
// Dropdown menu container and content !default;

$dropdown-color: #212529 !default;
$dropdown-margin-top: 0.125rem !default;
$dropdown-box-shadow: $box-shadow-2 !default;
$dropdown-font-size: 0.875rem !default;
$dropdown-item-border-radius: 0.25rem !default;
$dropdown-item-padding-y: 0.5rem !default;
$dropdown-item-padding-x: 1rem !default;

$dropdown-state-color: #16181b !default;
$dropdown-state-background-color: #eee !default;

$dropdown-menu-animated-animation-duration: 0.55s !default;
$dropdown-menu-animated-animation-timing-function: ease !default;

// Pagination

$pagination-font-size: 0.9rem !default;
$pagination-color: #212529 !default;
$pagination-transition: all 0.3s linear !default;
$pagination-border-radius: 0.125rem !default;

$pagination-hover-color: $pagination-color !default;
$pagination-active-box-shadow: $btn-box-shadow !default;
$pagination-active-transition: all 0.2s linear !default;

$pagination-circle-border-radius: 50% !default;
$pagination-circle-padding-x: 0.841rem !default;

$pagination-circle-lg-padding-left: 1.399414rem !default;
$pagination-circle-lg-padding-right: 1.399415rem !default;

$pagination-circle-sm-padding-left: 0.696rem !default;
$pagination-circle-sm-padding-right: 0.688rem !default;

// Cards

$card-box-shadow: $box-shadow-3 !default;
$card-header-background-color: rgba(255, 255, 255, 0) !default;
$card-footer-background-color: rgba(255, 255, 255, 0) !default;
$card-border-radius: 0.25rem !default;

// Accordion
$accordion-button-icon: none !default;
$accordion-button-active-icon: none !default;

// Tooltips

$tooltip-inner-color: #fff !default;
$tooltip-inner-padding-y: 6px !default;
$tooltip-inner-padding-x: 16px !default;
$tooltip-inner-font-size: 14px !default;
$tooltip-inner-background-color: #6d6d6d !default;
$tooltip-inner-border-radius: 0.25rem !default;

// Popovers

$popover-box-shadow: $box-shadow-2 !default;
$popover-background-color: #fff !default;

// Toasts

// $toast-background-color: #fff !default;
// $toast-box-shadow: $box-shadow-2 !default;
// $toast-header-background-color: #fff !default;

// Badges

$badge-dot-border-radius: 4.5px !default;
$badge-dot-height: 9px !default;
$badge-dot-width: $badge-dot-height !default;
$badge-dot-margin-left: -0.3125rem !default;

$badge-notification-font-size: 0.6rem !default;
$badge-notification-margin-top: -0.1rem !default;
$badge-notification-margin-left: -0.5rem !default;
$badge-notification-padding-y: 0.2em !default;
$badge-notification-padding-x: 0.45em !default;

// Modals

$modal-box-shadow: $box-shadow-3 !default;

// Progress bars

$progress-height: 4px !default;

// Breadcrumbs

$breadcrumb-item-color: rgba(0, 0, 0, 0.55) !default;
$breadcrumb-item-transition: color 0.15s ease-in-out !default;
$breadcrumb-item-hover-color: rgba(0, 0, 0, 0.7) !default;
$breadcrumb-item-before-color: $breadcrumb-item-color !default;

// Carousel

$carousel-control-prev-icon-bg: none;
$carousel-control-next-icon-bg: none;

// Images

$image-hover-overlay-transition: all 0.3s ease-in-out !default;

$image-hover-zoom-transition: all 0.3s linear !default;
$image-hover-zoom-transform: scale(1.1) !default;

$image-hover-shadow-transition: $image-hover-overlay-transition !default;
$image-hover-shadow-box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !default;

// Scrollspy

$scrollspy-menu-sidebar-font-size: 0.8rem !default;
$scrollspy-menu-sidebar-color: #262626 !default;
$scrollspy-menu-sidebar-line-height: 1.1rem !default;
$scrollspy-menu-sidebar-padding-x: 5px !default;
$scrollspy-menu-sidebar-font-weight: 400 !default;
$scrollspy-menu-sidebar-transition: all 0.2s ease-in-out !default;
$scrollspy-menu-sidebar-margin-y: 3px !default;

$scrollspy-menu-sidebar-active-color: $primary !default;
$scrollspy-menu-sidebar-active-font-weight: 600 !default;
$scrollspy-menu-sidebar-active-border-width: 0.125rem !default;
$scrollspy-menu-sidebar-active-border-color: $primary !default;

// Breadcrumbs

$breadcrumb-item-color: rgba(0, 0, 0, 0.55) !default;
$breadcrumb-item-transition: color 0.15s ease-in-out !default;
$breadcrumb-item-hover-color: rgba(0, 0, 0, 0.7) !default;
$breadcrumb-item-before-color: $breadcrumb-item-color !default;

// Images

$image-hover-overlay-transition: all 0.3s ease-in-out !default;

$image-hover-zoom-transition: all 0.3s linear !default;
$image-hover-zoom-transform: scale(1.1) !default;

$image-hover-shadow-transition: $image-hover-overlay-transition !default;
$image-hover-shadow-box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !default;

// Scrollspy

$scrollspy-menu-sidebar-font-size: 0.8rem !default;
$scrollspy-menu-sidebar-color: #262626 !default;
$scrollspy-menu-sidebar-line-height: 1.1rem !default;
$scrollspy-menu-sidebar-padding-x: 5px !default;
$scrollspy-menu-sidebar-font-weight: 400 !default;
$scrollspy-menu-sidebar-transition: all 0.2s ease-in-out !default;
$scrollspy-menu-sidebar-margin-y: 3px !default;

$scrollspy-menu-sidebar-active-color: $primary !default;
$scrollspy-menu-sidebar-active-font-weight: 600 !default;
$scrollspy-menu-sidebar-active-border-width: 0.125rem !default;
$scrollspy-menu-sidebar-active-border-color: $primary !default;

// Type

$note-padding: 10px !default;
$note-border-width: 6px !default;
$note-border-radius: 5px !default;
$note-strong-font-weight: 600 !default;
$note-paragraph-font-weight: 500 !default;

// Table

$table-color: #212529 !default;
$table-font-size: 0.9rem !default;
$table-hover-transition: background-color 0.2s ease-in !default;
$table-group-separator-color: inherit !default;
$table-cell-padding-y: 1rem !default;
$table-cell-padding-x: 1.4rem !default;
$table-cell-padding-y-sm: 0.5rem !default;
$table-cell-padding-x-sm: $table-cell-padding-x !default;

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.fullHeight {
  min-height: calc(100vh - 70px);
}

#DisplayWrapper {
  top: 58px;
  right: 0;
  left: 0;
  z-index: 100;
  overflow: hidden;
}

@media (min-width: 992px) {
  .fullHeight-lg {
    min-height: calc(100vh - 70px);
  }
}

@media (min-width: 1090px) {
  #DisplayWrapper {
    z-index: 0;
    top: 66px;
  }
}

.iconShowHover {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  position: relative;
  text-align: center;
  background-color: #0002;
}

.iconShowHover:after {
  content: "";
  background: #ffa900;
  position: absolute;
  bottom: 1px;
  left: 50%;
  height: 1px;
  width: 0px;
  transition: all 0.2s ease-in-out;
}

.iconShowHover.active {
  color: #ffa900;
}

.iconShowHover:hover:after,
.iconShowHover.active:after {
  left: 0%;
  width: 100%;
}

.cursor-pointer {
  cursor: pointer !important;
}

.iconScrollLeft,
.iconScrollRight {
  opacity: 0.3;
  cursor: pointer;
  right: 0px;
  top: 9px;
  position: absolute;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 1.5em;
  border-radius: 50rem;
  // background-color: #fff4;
}

.iconScrollRight {
  left: 0px;
  right: auto;
}

.iconScrollLeft:hover,
.iconScrollRight:hover {
  opacity: 1;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-10 {
  opacity: 0.1 !important;
}

.opacity-20 {
  opacity: 0.2 !important;
}

.opacity-30 {
  opacity: 0.3 !important;
}

.opacity-40 {
  opacity: 0.4 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-60 {
  opacity: 0.6 !important;
}

.opacity-70 {
  opacity: 0.7 !important;
}

.opacity-80 {
  opacity: 0.8 !important;
}

.opacity-90 {
  opacity: 0.9 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.form-outline .form-label {
  transform: translateY(-1rem) translateY(0.1rem) scale(0.8) !important;
  top: 3px !important;
  color: $neon !important;
  text-shadow: 0px 0px 10px $neon;
}

.form-outline:hover .form-label {
  color: #fff !important;
  text-shadow: none !important;
}

.form-outline .form-notch .form-notch-leading {
  border-right: none !important;
  border-top: 0.125rem solid $neon;
  border-bottom: 0.125rem solid $neon;
  border-left: 0.125rem solid $neon;
}

.form-outline .form-notch .form-notch-middle {
  border-bottom: 0.125rem solid;
  border-color: #1266f1;
}

.form-outline .form-notch .form-notch-middle,
.form-outline .form-notch .form-notch-middle {
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  transition: none !important;
}

.form-outline .form-notch .form-notch-trailing {
  border-top: 0.125rem solid $neon;
  border-bottom: 0.125rem solid $neon;
  border-left: 0.125rem solid $neon;
  transition: none !important;
}

.form-outline .form-notch .form-notch-trailing,
.form-outline .form-notch .form-notch-trailing {
  border-left: none !important;
  transition: none !important;
}

.form-outline:hover .form-notch .form-notch-leading,
.form-outline .form-control:focus ~ .form-notch .form-notch-leading {
  border-top: 0.125rem solid $neon;
  border-bottom: 0.125rem solid $neon;
  border-left: 0.125rem solid $neon;
  transition: none !important;
}

.form-outline .form-notch .form-notch-middle {
  min-width: 40px;
}

.form-outline:hover .form-notch .form-notch-middle,
.form-outline .form-control:focus ~ .form-notch .form-notch-middle {
  border-bottom: 0.125rem solid;
  border-color: $neon;
  transition: none !important;
}

.form-outline:hover .form-notch .form-notch-trailing,
.form-outline .form-control:focus ~ .form-notch .form-notch-trailing {
  border-top: 0.125rem solid $neon;
  border-bottom: 0.125rem solid $neon;
  border-right: 0.125rem solid $neon;
  transition: none !important;
}

.form-outline .form-notch .form-notch-middle,
.form-outline .form-notch .form-notch-middle {
  transition: none !important;
}

.form-outline .form-control:focus ~ .form-notch .form-notch-leading,
.form-outline .form-control.active ~ .form-notch .form-notch-leading {
  transition: none !important;
}
// Splash Software
.text-gradient {
  background: linear-gradient(to right, $theme1, $theme2);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-pink {
  color: $theme1 !important;
}

.border-pink {
  border-color: $theme1 !important;
}

.text-shadow-pink {
  text-shadow: 0px 0px 10px $theme1 !important;
}

.bg-gradient {
  background: linear-gradient(to right, $theme1, $theme2) !important;
}

.text-neon {
  color: $neon !important;
}

.text-shadow-neon {
  text-shadow: 0px 0px 10px $neon !important;
}

.text-shadow-success {
  text-shadow: 0px 0px 10px $success !important;
}

.text-shadow-info {
  text-shadow: 0px 0px 10px $info !important;
}

.text-shadow-danger {
  text-shadow: 0px 0px 10px $danger !important;
}

.text-shadow-warning {
  text-shadow: 0px 0px 10px $warning !important;
}

.text-shadow-white {
  text-shadow: 0px 0px 10px $white !important;
}

// BOX SHADOW
.box-shadow-neon {
  box-shadow: 0px 0px 10px $neon !important;
}

.box-shadow-success {
  box-shadow: 0px 0px 10px $success !important;
}

.box-shadow-info {
  box-shadow: 0px 0px 10px $info !important;
}

.box-shadow-danger {
  box-shadow: 0px 0px 10px $danger !important;
}

.box-shadow-warning {
  box-shadow: 0px 0px 10px $warning !important;
}

.box-shadow-white {
  box-shadow: 0px 0px 10px $white !important;
}

.box-shadow-none {
  box-shadow: none !important;
}

.border-neon {
  border-color: $neon !important;
}

.box-hover-shadow-neon:hover {
  box-shadow: 0px 0px 10px $neon !important;
}

.box-hover-shadow-success:hover {
  box-shadow: 0px 0px 10px $success !important;
}

.box-hover-shadow-warning:hover {
  box-shadow: 0px 0px 10px $warning !important;
}

.text-hover-neon:hover {
  transition: all 0.2s ease-in-out;
  color: $neon !important;
}

.text-hover-shadow-neon:hover {
  transition: all 0.2s ease-in-out;
  text-shadow: 0px 0px 0.5rem $neon !important;
}

.text-hover-shadow-neon:hover svg {
  filter: drop-shadow(0px 0px 0.5rem $neon) !important;
}

.svg-shadow-neon {
  filter: drop-shadow(0px 0px 0.5rem $neon) !important;
}

.svg-hover-shadow-neon:hover {
  transition: all 0.2s ease-in-out;
  filter: drop-shadow(0px 0px 0.5rem $neon) !important;
}

.neonProgress {
  position: relative;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background: linear-gradient(45deg, transparent, transparent 40%, $neon), linear-gradient(#fff0, #fff0);
  animation: neonProgressRotate 0.5s linear infinite;
}

.neonProgress:before,
.neonProgress:after {
  content: " ";
  position: absolute;
  top: 0.2rem;
  left: 0.2rem;
  right: 0.2rem;
  bottom: 0.2rem;
  background: linear-gradient(#000, #000);
  border-radius: inherit;
}

.neonProgress:before {
  background: linear-gradient(45deg, transparent, transparent 40%, $neon);
  filter: blur(0.1rem);
}

@keyframes neonProgressRotate {
  0% {
  }

  100% {
    transform: rotate(360deg);
    filter: hue-rotate(360deg);
    // filter: brightness(2);
  }
}

.animate__animated.animate__delay-01s {
  -webkit-animation-delay: 0.1s;
  animation-delay:  0.1s;
}

.animate__animated.animate__delay-02s {
  -webkit-animation-delay: 0.2s;
  animation-delay:  0.2s;
}
.animate__animated.animate__delay-03s {
  -webkit-animation-delay: 0.3s;
  animation-delay:  0.3s;
}
.animate__animated.animate__delay-04s {
  -webkit-animation-delay: 0.4s;
  animation-delay:  0.4s;
}

.animate__animated.animate__delay-05s {
  -webkit-animation-delay: 0.5s;
  animation-delay:  0.5s;
}

.animate__animated.animate__delay-06s {
  -webkit-animation-delay: 0.6s;
  animation-delay:  0.6s;
}

.animate__animated.animate__delay-07s {
  -webkit-animation-delay: 0.7s;
  animation-delay:  0.7s;
}

.animate__animated.animate__delay-08s {
  -webkit-animation-delay: 0.8s;
  animation-delay:  0.8s;
}

.animate__animated.animate__delay-09s {
  -webkit-animation-delay: 0.9s;
  animation-delay:  0.9s;
}

// #POKER [data-name=Stripes], #POKER #Stripes{
//   transform-origin: center center;
//   transform-box: fill-box;
//    animation: rotating 10s linear infinite;
//   // transform: rotate(20deg);
// }

// @keyframes rotating {
//   from{
//       transform: rotate(0deg);
//   }
//   to{
//       transform: rotate(360deg);
//   }
// }
